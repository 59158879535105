var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Structure juridique" },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: { indeterminate: "", active: _vm.loading },
              }),
              _c("DetailViewBoxComponent", {
                attrs: {
                  title: "Détail de la structure juridique",
                  id: "detailid",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nom digital",
                            value: _vm.digitalName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nom abrégé",
                            value: _vm.shortName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nature",
                            value: _vm.type,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nom complet",
                            value: _vm.fullName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "statut",
                            value: _vm.status,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "siège social",
                            value: _vm.headOffice,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / rue",
                            value: _vm.addressRoad,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / complément d'adresse",
                            value: _vm.addressRoad2,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / code postal",
                            value: _vm.addressPostalCode,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / ville",
                            value: _vm.addressCity,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / latitude",
                            value: _vm.addressLatitude,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "adresse / longitude",
                            value: _vm.addressLongitude,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "siren",
                            value: _vm.siren,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "rcs",
                            value: _vm.rcs,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "APE",
                            value: _vm.ape,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "représentant légal",
                            value: _vm.legalRepresentative,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "qualité du représentant légal",
                            value: _vm.legalRepresentativeStatus,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "capital ou fond associatif/mutualiste",
                            value: _vm.capital,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "lien acte de cession",
                            value: _vm.cessionActLink,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "lien jugement de cession",
                            value: _vm.cessionJudgmentLink,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "gestionnaire finess",
                            value: _vm.managerFiness,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "Nom du commissaire aux comptes",
                            value: _vm.statutoryAuditorName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nombre de titres ou de droits de vote",
                            value: _vm.numberOfCompanySecurities,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "ID drive procès-verbal assemblée générale",
                            value: _vm.idDriveMinutesGeneralMeeting,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "Indice d'égalité professionnelle",
                            value: _vm.professionalEqualityIndex,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "TVA intracommunautaire",
                            value: _vm.intraCommunityVAT,
                            divider: false,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "v-card",
                {
                  staticClass: "my-4",
                  attrs: { flat: "", outlined: "", id: "stockholderid" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" },
                        },
                        [
                          _c("div", [
                            _vm._v("Les actionnaires, membres ou adhérents"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      locale: "fr-FR",
                      headers: _vm.headerStockholders,
                      items: _vm.stockholders,
                      "no-data-text": "aucun actionnaire, membre ou adhérent",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "my-4",
                  attrs: { flat: "", outlined: "", id: "externalcontractid" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" },
                        },
                        [_c("div", [_vm._v("Les contrats externes")])]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      locale: "fr-FR",
                      headers: _vm.headerExternalContracts,
                      items: _vm.contracts,
                      "no-data-text": "aucun contrat",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "my-4",
                  attrs: { flat: "", outlined: "", id: "eventid" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" },
                        },
                        [_c("div", [_vm._v("Les évènements")])]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      locale: "fr-FR",
                      headers: _vm.headerEvents,
                      items: _vm.events,
                      "no-data-text": "aucun événement",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: _vm.getItemLink(),
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-grow-0 justify-center",
                                },
                                [
                                  item.docLink
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                            disabled: _vm.disableButtonOpen(
                                              item.docLink
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openLinkTab(
                                                item.docLink
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-open-in-new ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "my-4",
                  attrs: { flat: "", outlined: "", id: "eventid" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" },
                        },
                        [_c("div", [_vm._v("Les établissements rattachés")])]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      locale: "fr-FR",
                      headers: _vm.headerEstabs,
                      items: _vm.estabs,
                      "no-data-text": "aucun établissemant",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
      _c(
        "v-layout",
        { staticClass: "fab-container", attrs: { column: "" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll",
                },
              ],
              staticClass: "mx-2 my-2",
              attrs: {
                fab: "",
                elevation: "0",
                disabled: !_vm.canScrollToTop,
                color: "primary",
              },
              on: { click: _vm.toTop },
            },
            [
              _c("v-icon", { attrs: { disabled: !_vm.canScrollToTop } }, [
                _vm._v("mdi-chevron-up"),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.onScroll,
                  expression: "onScroll",
                },
              ],
              staticClass: "mx-2 my-1",
              attrs: {
                fab: "",
                elevation: "0",
                disabled: !_vm.canScrollToBottom,
                color: "primary",
              },
              on: { click: _vm.toBottom },
            },
            [
              _c("v-icon", { attrs: { disabled: !_vm.canScrollToBottom } }, [
                _vm._v("mdi-chevron-down"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }