<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Structure juridique" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <!-- le détail  -->
        <DetailViewBoxComponent
          title="Détail de la structure juridique"
          id="detailid"
        >
          <template v-slot:items>
            <InputFieldComponent
              :cols="cols"
              label="nom digital"
              :value="digitalName"
            />

            <InputFieldComponent
              :cols="cols"
              label="nom abrégé"
              :value="shortName"
            />

            <InputFieldComponent :cols="cols" label="nature" :value="type" />

            <InputFieldComponent
              :cols="cols"
              label="nom complet"
              :value="fullName"
            />

            <InputFieldComponent :cols="cols" label="statut" :value="status" />

            <InputFieldComponent
              :cols="cols"
              label="siège social"
              :value="headOffice"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / rue"
              :value="addressRoad"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / complément d'adresse"
              :value="addressRoad2"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / code postal"
              :value="addressPostalCode"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / ville"
              :value="addressCity"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / latitude"
              :value="addressLatitude"
            />

            <InputFieldComponent
              :cols="cols"
              label="adresse / longitude"
              :value="addressLongitude"
            />

            <InputFieldComponent :cols="cols" label="siren" :value="siren" />

            <InputFieldComponent :cols="cols" label="rcs" :value="rcs" />

            <InputFieldComponent :cols="cols" label="APE" :value="ape" />

            <InputFieldComponent
              :cols="cols"
              label="représentant légal"
              :value="legalRepresentative"
            />

            <InputFieldComponent
              :cols="cols"
              label="qualité du représentant légal"
              :value="legalRepresentativeStatus"
            />

            <InputFieldComponent
              :cols="cols"
              label="capital ou fond associatif/mutualiste"
              :value="capital"
            />

            <InputFieldComponent
              :cols="cols"
              label="lien acte de cession"
              :value="cessionActLink"
            />

            <InputFieldComponent
              :cols="cols"
              label="lien jugement de cession"
              :value="cessionJudgmentLink"
            />

            <InputFieldComponent
              :cols="cols"
              label="gestionnaire finess"
              :value="managerFiness"
            />

            <InputFieldComponent
              :cols="cols"
              label="Nom du commissaire aux comptes"
              :value="statutoryAuditorName"
            />

            <InputFieldComponent
              :cols="cols"
              label="nombre de titres ou de droits de vote"
              :value="numberOfCompanySecurities"
            />

            <InputFieldComponent
              :cols="cols"
              label="ID drive procès-verbal assemblée générale"
              :value="idDriveMinutesGeneralMeeting"
            />

            <InputFieldComponent
                :cols="cols"
                label="Indice d'égalité professionnelle"
                :value="professionalEqualityIndex"
            />

            <InputFieldComponent
              :cols="cols"
              label="TVA intracommunautaire"
              :value="intraCommunityVAT"
              :divider="false"
            />
          </template>
        </DetailViewBoxComponent>

        <!-- les actionnaires -->
        <v-card flat outlined class="my-4" id="stockholderid">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters justify="space-between">
              <div>Les actionnaires, membres ou adhérents</div>
            </v-row>
          </v-card-title>

          <v-data-table
            locale="fr-FR"
            :headers="headerStockholders"
            :items="stockholders"
            no-data-text="aucun actionnaire, membre ou adhérent"
            disable-pagination
            hide-default-footer
          >
          </v-data-table>
        </v-card>

        <!-- les contrats -->
        <v-card flat outlined class="my-4" id="externalcontractid">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters justify="space-between">
              <div>Les contrats externes</div>
            </v-row>
          </v-card-title>

          <v-data-table
            locale="fr-FR"
            :headers="headerExternalContracts"
            :items="contracts"
            no-data-text="aucun contrat"
            disable-pagination
            hide-default-footer
          >
          </v-data-table>
        </v-card>

        <!-- les événements -->
        <v-card flat outlined class="my-4" id="eventid">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters justify="space-between">
              <div>Les évènements</div>
            </v-row>
          </v-card-title>

          <v-data-table
            locale="fr-FR"
            :headers="headerEvents"
            :items="events"
            no-data-text="aucun événement"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[getItemLink()]="{ item }">
              <div class="d-flex flex-grow-0 justify-center">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="openLinkTab(item.docLink)"
                  :disabled="disableButtonOpen(item.docLink)"
                  v-if="item.docLink"
                >
                  mdi-open-in-new
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>

        <!-- les établissements -->
        <v-card flat outlined class="my-4" id="eventid">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters justify="space-between">
              <div>Les établissements rattachés</div>
            </v-row>
          </v-card-title>

          <v-data-table
            locale="fr-FR"
            :headers="headerEstabs"
            :items="estabs"
            no-data-text="aucun établissemant"
            disable-pagination
            hide-default-footer
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >

    <v-layout column class="fab-container">
      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToTop"
        color="primary"
        @click="toTop"
      >
        <v-icon :disabled="!canScrollToTop">mdi-chevron-up</v-icon>
      </v-btn>

      <v-btn
        class="mx-2 my-1"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToBottom"
        @click="toBottom"
        color="primary"
      >
        <v-icon :disabled="!canScrollToBottom">mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";
import { LegalStructureStockholderService } from "@/service/etablishment/legal_structure_stockholder_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import { LegalStructureExternalContractService } from "@/service/etablishment/legal_structure_external_contract_service.js";
import { LegalStructureEventsService } from "@/service/etablishment/legal_structure_events_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { checkUrl } from "@/tools/url_tool.js";

export default {
  name: "DetailLegalStructure",

  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceLegalStructure: null,

      serviceUsers: null,

      serviceLegalStructureStockholder: null,

      serviceExternalContracts: null,

      serviceEvents: null,

      serviceEstablishments: null,

      serviceActivities: null,

      serviceMetiers: null,

      /**l'identifiant  à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**le nom digital */
      digitalName: null,

      /**le nom abrégé de la structure juridique */
      shortName: null,

      /**la nature de la structure juridique */
      type: null,

      /**le nom complet de la structure juridique */
      fullName: null,

      /**le siren de la structure juridique */
      siren: null,

      /**le statut de la structure juridique */
      status: null,

      /**L'adresse de la structure juridique */
      addressRoad: null,

      addressRoad2: null,

      addressPostalCode: null,

      addressCity: null,

      addressLatitude: null,

      addressLongitude: null,

      ape: null,

      capital: null,

      legalRepresentative: null,

      legalRepresentativeStatus: null,

      cessionActLink: null,

      cessionJudgmentLink: null,

      managerFiness: null,

      rcs: null,

      statutoryAuditorName: null,

      numberOfCompanySecurities: null,

      idDriveMinutesGeneralMeeting: null,

      professionalEqualityIndex: null,

      intraCommunityVAT: null,

      headOffice: null,

      /**les actionnaires */
      stockholders: [],

      /**les contrats externes */
      contracts: [],

      /**les événements */
      events: [],

      /** les établissements rattachés */
      estabs: [],
      /** les activitées de la bdd */
      activities: [],
      /** Les métiers de la bdd */
      metiers: [],

      /**Boutons pour scroller tout en bas et tout en haut  */
      canScrollToTop: false,
      canScrollToBottom: true,

      headerStockholders: [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "stockholder.name",
        },
        {
          text: "Pourcentage",
          align: "start",
          sortable: true,
          value: "labelPercent",
        },
      ],

      headerExternalContracts: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "contractType.label",
        },
        {
          text: "Nom contact",
          align: "start",
          sortable: true,
          value: "contractContact.name",
        },
        {
          text: "Adresse contact",
          align: "start",
          sortable: true,
          value: "contractContact.address",
        },
      ],

      headerEvents: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "eventType.label",
        },
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Lien",
          sortable: false,
          align: "center",
          width: "80px",
          value: "link",
        },
      ],

      headerEstabs: [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metierName",
        },
        {
          text: "Activités",
          align: "start",
          sortable: true,
          value: "activitiesName",
        },
        {
          text: "Statut",
          sortable: false,
          align: "center",
          value: "status",
        },
        {
          text: "Nom commercial",
          sortable: false,
          align: "center",
          value: "commercialName",
        },
      ],

      cols: [6, 6],

      content: [
        {
          id: "detailid",
          label: "Détail",
          selected: true,
        },
        {
          id: "stockholderid",
          label: "Actionnaires, membres ou adhérents",
          selected: false,
        },
        {
          id: "externalcontractid",
          label: "Contrats externes",
          selected: false,
        },
        {
          id: "eventid",
          label: "Événements",
          selected: false,
        },
      ],

      selectedContent: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // chargement de la structure juridique
        let entity = await this.serviceLegalStructure.getById(this.entityId);
        console.log("load entity  " + JSON.stringify(entity, null, 4));

        let stockholders =
          await this.serviceLegalStructureStockholder.getViewByLegalStructure(
            this.entityId
          );

        stockholders.forEach((stockholder) => {
          if (stockholder.percent) {
            stockholder.labelPercent = stockholder.percent + "%";
          } else {
            stockholder.labelPercent = "";
          }
        });

        this.stockholders = stockholders;

        this.contracts = await this.serviceExternalContracts.getView(
          this.entityId
        );

        this.events = await this.serviceEvents.getView(this.entityId);

        // Récupération de l'ensemble des établissements rattachés à la structure juridique
        const estabsAttached =
          await this.serviceLegalStructure.getAllAttachedEstablishment(
            this.entityId
          );
        // Récupération de l'ensemble des activités de la bdd
        this.activities = await this.serviceActivities.getActivities();
        // Récupération de l'ensemble des métiers de la bdd
        this.metiers = await this.serviceMetiers.getAll();

        // Association entre établissement, activités et métier
        // Pour chaque établissement, ajout de ses noms d'activités et des nom de métiers
        estabsAttached.forEach((e) => {
          // Récupération des noms d'activités
          e.activitiesName = e.activityIds
            .map((a) => {
              return this.activities.find((ac) => ac.id == a).name;
            })
            .join(", ");
          e.metierName = this.metiers.find((m) => e.metierId == m.id).name;
        });
        this.estabs = estabsAttached;

        // chargement du représentant légal
        console.log("entity: " + JSON.stringify(entity));
        if (entity.legalRepresentativeUuid) {
          let user = await this.serviceUsers.getByUuid(
            entity.legalRepresentativeUuid
          );
          //   console.log(JSON.stringify(user, null, 4));

          this.legalRepresentative =
            (user.person.firstname || "") + " " + (user.person.name || "");
        } else {
          this.legalRepresentative = "-";
        }

        this.digitalName = entity.digitalName;
        this.shortName = entity.shortName || "-";
        this.type = entity.type;
        this.fullName = entity.fullName || "-";
        this.status = entity.status;

        if (entity.address) {
          this.addressRoad = entity.address.address || "-";
          this.addressRoad2 = entity.address.address2 || "-";
          this.addressPostalCode = entity.address.postalCode || "-";
          this.addressCity = entity.address.city || "-";
          this.addressLatitude = entity.address.latitude || "-";
          this.addressLongitude = entity.address.longitude || "-";
        } else {
          this.addressRoad = "-";
          this.addressRoad2 = "-";
          this.addressPostalCode = "-";
          this.addressCity = "-";
          this.addressLatitude = "-";
          this.addressLongitude = "-";
        }

        this.siren = entity.siren || "-";
        this.rcs = entity.rcs || "-";
        this.ape = entity.ape || "-";

        this.legalRepresentativeStatus =
          entity.legalRepresentativeStatus || "-";

        this.capital = entity.capital ? entity.capital + "€" : "-";

        this.cessionActLink = entity.cessionActLink || "-";
        this.cessionJudgmentLink = entity.cessionJudgmentLink || "-";

        this.managerFiness = entity.managerFiness || "-";

        this.statutoryAuditorName = entity.statutoryAuditorName || "-";
        this.numberOfCompanySecurities =
          entity.numberOfCompanySecurities || "-";
        this.idDriveMinutesGeneralMeeting =
          entity.idDriveMinutesGeneralMeeting || "-";

        this.professionalEqualityIndex =
          entity.professionalEqualityIndex || "-";

        this.intraCommunityVAT = entity.intraCommunityVAT || "-";

        if (entity.headOfficeId) {
          let establishment = await this.serviceEstablishments.getById(
            entity.headOfficeId
          );
          this.headOffice = establishment.digitalName;
        } else {
          this.headOffice = "-";
        }

        // console.log(JSON.stringify(entity, null, 4));
        // console.log(JSON.stringify(this.stockholders, null, 4));
        // console.log(JSON.stringify(this.contracts, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const currentPos = window.pageYOffset || e.target.scrollTop || 0;
      const bottom = document.body.scrollHeight - window.innerHeight;
      this.canScrollToTop = currentPos > bottom * 0.15;
      this.canScrollToBottom = currentPos < bottom * 0.85;

      this.scrollInContent();
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },
    toContent(item) {
      var div = document.getElementById(item.id);

      this.$vuetify.goTo(div.offsetTop);
    },
    /**fonction pour déterminer le nom du slot du v-data-table. sinon cela provoque une erreur eslint. */
    getItemLink() {
      return `item.link`;
    },
    openLinkTab(link) {
      let url = link;
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }
      window.open(url, "_blank");
    },
    disableButtonOpen(url) {
      if (!url) {
        //pas de lien, on désactive le bouton
        return true;
      }

      if (!checkUrl(url)) {
        return true;
      } else {
        return false;
      }
    },
    getContentClass(item) {
      if (item.selected) {
        return "text-subtitle-2 font-weight-normal";
      } else {
        return "text-subtitle-2 font-weight-light";
      }
    },
    selectContentItem(item) {
      this.content.forEach((c) => {
        c.selected = false;
      });
      item.selected = true;

      this.toContent(item);
    },
    scrollInContent() {
      let visibles = [];
      let partials = [];

      for (let item of this.content) {
        var div = document.getElementById(item.id);

        var position = div.getBoundingClientRect();

        // checking whether fully visible
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
          visibles.push(item);
          continue;
        }

        // checking for partial visibility
        if (position.top < window.innerHeight && position.bottom >= 0) {
          partials.push(item);
        }
      }

      let target = null;

      if (visibles.length > 0) {
        target = visibles[0];
      } else if (partials.length > 0) {
        target = partials[0];
      }

      //on désélectionne tout
      this.content.forEach((c) => {
        c.selected = false;
      });

      if (target) {
        target.selected = true;
      }
    },
  },
  computed: {},
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceLegalStructure = new LegalStructureService(
      this.$api.getLegalStructuresApi()
    );

    this.serviceUsers = new UsersService(this.$api.getUsersApi());

    this.serviceLegalStructureStockholder =
      new LegalStructureStockholderService(
        this.$api.getLegalStructureStockholderApi()
      );

    this.serviceExternalContracts = new LegalStructureExternalContractService(
      this.$api.getLegalStructureExternalContractApi()
    );

    this.serviceEvents = new LegalStructureEventsService(
      this.$api.getLegalStructureEventApi()
    );

    this.serviceEstablishments = new EstablishmentsService(this.$api);

    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();

    this.selectedContent = this.content[0];
  },
};
</script>

<style>
.fab-container {
  margin: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}
.fab-content {
  margin: 10px;
  position: fixed;
  top: 200px;
  bottom: 0;
  right: 50px;
}
</style>
